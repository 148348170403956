import { LayoutGroup, motion, useReducedMotion, Variants } from "framer-motion";
import debounce from "lodash/debounce";
import { useEffect, useMemo, useState } from "react";
import Div100vh from "react-div-100vh";
import { Helmet } from "react-helmet-async";
import { useNavigate, useParams } from "react-router-dom";
import { createInventoryUrl, createListingUrl } from "src/RouteUrls";
import {
  BasicOverlay,
  Button,
  ContactUsFlyout,
  HBLoadingSpinner,
  Icon,
  Map,
  MapMarker,
  Modal,
  Nav,
  SelectField,
} from "~components";
import { Css } from "~generated/css";
import { InventoryMarketFragment, useInventoryFiltersQuery, useInventoryPropertiesQuery } from "~generated/graphql";
import { useContainerBreakpoints, useTestIds } from "~utils";

import {
  convertFormToFilter,
  FilterState,
  InventoryPropertyCard,
  minBathOptions,
  minBedOptions,
  priceOptions,
  PropertyCardContainerPrefix,
  propertyTagFilter,
  squareFootageOptions,
} from "./Inventory.utils";

const LOCAL_BREAKPOINT = 540;

export function InventoryPage() {
  const { ref, breakpoint } = useContainerBreakpoints<HTMLDivElement>({
    mobile: [0, LOCAL_BREAKPOINT],
    desktop: [LOCAL_BREAKPOINT + 1],
  });

  return (
    <>
      <Div100vh ref={ref} css={Css.bgWhite.df.fdc.$}>
        {breakpoint.desktop && <DesktopView />}
        {breakpoint.mobile && <MobileView />}
      </Div100vh>
    </>
  );
}

function DesktopView() {
  const { marketSlug } = useParams();
  const navigate = useNavigate();

  const shouldReduceMotion = useReducedMotion();
  const preferredScrollBehavior = shouldReduceMotion ? "auto" : "smooth";

  const [filters, setFilters] = useState<FilterState>({});
  const [selectedMarket, setSelectedMarket] = useState<InventoryMarketFragment>();
  const [hoveredPropertyId, setHoveredPropertyId] = useState<string | null>(null);
  const [selectedPropertyId, setSelectedPropertyId] = useState<string | null>(null);
  const [displayContactFlyout, setDisplayContactFlyout] = useState<boolean>(false);

  const tid = useTestIds({}, "marketInventory");

  const { data } = useInventoryFiltersQuery();
  const markets = useMemo(() => data?.markets || [], [data?.markets]);
  const cutoffFilterOptions = useMemo(() => {
    return data?.cutOffs
      ? data.cutOffs.map((co) => {
          return {
            value: co.marketingName,
            info: co.description,
          };
        })
      : [];
  }, [data?.cutOffs]);
  const { data: propertyInventory, loading } = useInventoryPropertiesQuery({
    variables: { filter: convertFormToFilter(filters, selectedMarket?.id) },
    skip: !selectedMarket,
  });
  const properties = propertyTagFilter(filters, propertyInventory?.properties || []);

  function handleLocationChange(marketNames: string[]) {
    const [marketName] = marketNames;
    const chosenMarket = markets.find((m) => m.name === marketName)!;
    setSelectedMarket(chosenMarket);
    navigate(createInventoryUrl(chosenMarket), {
      replace: true,
    });
  }

  // initialize the selected market from the URL, if the url doesn't match a market maybe it is an old property url and redirect
  useEffect(() => {
    if (markets.length > 0) {
      const marketBPId = marketSlug!.split("-").pop();
      const initialMarket = markets.find((m) => m.blueprintMarketId === marketBPId);
      if (initialMarket) {
        setSelectedMarket(initialMarket);
      } else {
        window.location.href = `/inventory/m/${marketSlug}`;
      }
    }
  }, [marketSlug, markets]);
  return (
    <>
      <Helmet>
        <title>{`Browse Homes in ${selectedMarket?.name} | Homebound`}</title>
        <meta
          name="description"
          content={`Check out the homes we are building in ${selectedMarket?.name}. Choose your architecture style and customize your home. We make it simple.`}
        />
      </Helmet>
      <main css={Css.fg1.bgWhite.$}>
        <Nav />
        <div css={Css.wPx(1280).mx("auto").h100.mh("100vh").df.fdc.$}>
          <h1 {...tid.title} css={Css.header40.black.mt4.tc.$}>
            {selectedMarket?.name} Homes for Sale
          </h1>
          <p css={Css.mt2.body18.lhPx(28).tc.maxw("970px").mxa.$}>
            Homes with personalization available are under construction and allow buyers to choose finishes and features
            that suit their preferences. Homes that are Coming Soon are nearly complete, with some personalization still
            available. Move-in ready homes are fully built and ready for immediate move-in.
          </p>
          {selectedMarket?.name === "North Hollywood" && (
            <p css={Css.body18.lhPx(28).tc.maxw("970px").mxa.$}>
              Visit Lyon Lane{" "}
              <a
                css={{
                  ...Css.gray700.cursorPointer.underline.$,
                  "&:hover": Css.noUnderline.$,
                }}
                href="http://lyonlane.com/"
              >
                website
              </a>
            </p>
          )}
          {/* Filters */}
          {/* The negative margin is to cover the shadow on the cards when the element sticks */}
          <div css={Css.mt5.sticky.top("88px").bgWhite.z3.p1.ml("-8px").df.gap1.$}>
            <div css={Css.wPx(175).$}>
              <SelectField
                label="Location"
                selectedOptions={selectedMarket ? [selectedMarket.name] : []}
                onChange={handleLocationChange}
                options={markets?.map((m) => m.name)}
                allowDeselect={false}
                {...tid.filterLocation}
              />
            </div>
            <div css={Css.wPx(175).$}>
              <SelectField
                label="Price"
                placeholder="All"
                selectedOptions={filters.price ? [filters.price] : []}
                onChange={(price) => setFilters((prevForm) => ({ ...prevForm, price: price[0] }))}
                options={Object.keys(priceOptions)}
                {...tid.filterPrice}
              />
            </div>
            <div css={Css.wPx(150).$}>
              <SelectField
                label="Beds"
                placeholder="All"
                selectedOptions={filters.minBeds ? [filters.minBeds] : []}
                onChange={(bedSelection) => setFilters((prevForm) => ({ ...prevForm, minBeds: bedSelection[0] }))}
                options={Object.keys(minBedOptions)}
                {...tid.filterBeds}
              />
            </div>
            <div css={Css.wPx(150).$}>
              <SelectField
                label="Baths"
                placeholder="All"
                selectedOptions={filters.minBaths ? [filters.minBaths] : []}
                onChange={(bathSelection) => setFilters((prevForm) => ({ ...prevForm, minBaths: bathSelection[0] }))}
                options={Object.keys(minBathOptions)}
                {...tid.filterBaths}
              />
            </div>
            <div css={Css.wPx(150).$}>
              <SelectField
                label="Square Feet"
                placeholder="All"
                selectedOptions={filters.squareFootage ? [filters.squareFootage] : []}
                onChange={(squareFootage) =>
                  setFilters((prevForm) => ({ ...prevForm, squareFootage: squareFootage[0] }))
                }
                options={Object.keys(squareFootageOptions)}
                {...tid.filterSqft}
              />
            </div>
            <div css={Css.wPx(228).$}>
              <SelectField
                label="Home Readiness"
                placeholder="All"
                selectedOptions={filters.tags ? [filters.tags] : []}
                onChange={(tags) => setFilters((prevForm) => ({ ...prevForm, tags: tags[0] }))}
                options={cutoffFilterOptions}
                {...tid.filterTag}
              />
            </div>
          </div>
          {(loading || !selectedMarket) && <HBLoadingSpinner />}
          {!loading && selectedMarket && (
            <>
              {properties.length > 0 && (
                <div css={Css.dg.gap2.gtc("min-content auto").pt1.bgWhite.$}>
                  {/* Property Cards Column */}
                  <div css={Css.dg.gtc("min-content min-content").gtr("min-content").gap2.mwPx(616).$}>
                    {properties.map((property) => (
                      <InventoryPropertyCard
                        key={property.id}
                        property={property}
                        url={createListingUrl(selectedMarket, property)}
                        hoveredPropertyState={{ hoveredPropertyId, setHoveredPropertyId }}
                        selectedPropertyState={{
                          selectedPropertyId,
                          setSelectedPropertyId: (id) => {
                            setSelectedPropertyId(id);
                            setDisplayContactFlyout(true);
                          },
                        }}
                      />
                    ))}
                  </div>
                  {/* Map Column*/}
                  <div css={Css.sticky.top("172px").h("calc(100vh - 172px)").w100.$}>
                    <Map
                      markers={properties.map((property) => {
                        const { id, latitude, longitude } = property;

                        // Markers are active if they are selected or hovered
                        const isActive = selectedPropertyId === id || hoveredPropertyId === id;

                        return {
                          id,
                          center: { lat: Number(latitude), lng: Number(longitude) },
                          isActive,
                          marker: (
                            <MapMarker
                              isActive={isActive}
                              onClick={() => {
                                setSelectedPropertyId(id);
                                document
                                  .getElementById(`property-${id}`)
                                  ?.scrollIntoView({ behavior: preferredScrollBehavior, block: "center" });
                              }}
                              onHoverStart={() => setHoveredPropertyId(id)}
                              onHoverEnd={() => setHoveredPropertyId(null)}
                              data-testid={`mapMarker-${id}`}
                            />
                          ),
                        };
                      })}
                    />
                  </div>
                </div>
              )}
              {properties.length === 0 && (
                <div css={Css.df.fdc.aic.gap2.my2.bgOffWhite.$}>
                  <div css={Css.wPx(291).hPx(145).mt("120px").$}>
                    <img src="/undraw_house.png" alt="No properties found"></img>
                  </div>
                  <div css={Css.mt5.fw500.fs("32px").$}>No properties match that criteria</div>
                  <div css={Css.fw300.fs("18px").$}>Try adjusting some of the selected filters</div>
                  <Button
                    variant="secondary"
                    xss={Css.w("auto").mt4.mb("315px").$}
                    onClick={() => setFilters({})}
                    {...tid.clearFiltersButton}
                  >
                    Clear Filters
                  </Button>
                </div>
              )}
            </>
          )}
        </div>
        {selectedPropertyId && displayContactFlyout && (
          <ContactUsFlyout
            property={properties.find((p) => p.id === selectedPropertyId)!}
            onClose={() => {
              setHoveredPropertyId(null);
              setSelectedPropertyId(null);
              setDisplayContactFlyout(false);
            }}
            {...tid.contactUsFlyout}
          />
        )}
      </main>
    </>
  );
}

const drawerScrollContainerVariants: Variants = {
  horizontal: {
    flexDirection: "row",
    paddingLeft: "16px",
    paddingRight: "16px",
    paddingTop: "13px",
    paddingBottom: "13px",
    gap: "16px",
    scrollPadding: "24px",
    scrollSnapType: "x mandatory",
    transition: {
      staggerChildren: 0.1,
      delayChildren: 0.2,
      bounce: 0,
    },
  },
  vertical: {
    flexDirection: "column",
    height: "100%",
    gap: "24px",
    paddingTop: "16px",
    paddingBottom: "16px",
    scrollSnapType: "y mandatory",
    scrollSnapAlign: "center",
    transition: {
      staggerChildren: 0.1,
      delayChildren: 0.5,
      bounce: 0,
    },
  },
};

// TODO: Location is a "filter" but it navigates to a new page so other filter selections are lost.
//   It is a minor issue but a weird experience on mobile when compared to desktop
function MobileView() {
  const { marketSlug } = useParams();
  const navigate = useNavigate();

  const shouldReduceMotion = useReducedMotion();
  const preferredScrollBehavior = shouldReduceMotion ? "auto" : "smooth";

  const [queryFilters, setQueryFilters] = useState<Omit<FilterState, "tags">>({});
  // SelectField doesn't maintain it's own value so to reduce network calls we'll store what we expect to be frequently changed in a second state
  const [modalFilters, setModalFilters] = useState<Omit<FilterState, "tags">>({});
  const [showFilters, setShowFilters] = useState(false);
  // Tag filters are our only client side filter
  const [tagFilter, setTagFilter] = useState<string[]>([]);

  const [selectedMarket, setSelectedMarket] = useState<InventoryMarketFragment>();
  const [selectedPropertyId, setSelectedPropertyId] = useState<string | null>(null);
  const [displayContactFlyout, setDisplayContactFlyout] = useState<boolean>(false);

  const tid = useTestIds({}, "marketInventory");

  const { data, loading: loadingMarkets } = useInventoryFiltersQuery();
  const markets = useMemo(() => data?.markets || [], [data?.markets]);
  const cutOffs = useMemo(() => data?.cutOffs || [], [data?.cutOffs]);
  const { data: propertyInventory, loading: loadingInventory } = useInventoryPropertiesQuery({
    variables: { filter: convertFormToFilter(queryFilters, selectedMarket?.id) },
    skip: !selectedMarket,
  });
  const properties = propertyTagFilter({ tags: tagFilter[0] }, propertyInventory?.properties || []);

  // Mobile drawer expands up to fill content and will trigger expanded property cards
  const [drawerExpanded, setDrawerExpanded] = useState(false);

  function handleLocationChange(marketNames: string[]) {
    const [marketName] = marketNames;
    const chosenMarket = markets.find((m) => m.name === marketName)!;
    setSelectedMarket(chosenMarket);
    navigate(createInventoryUrl(chosenMarket), {
      replace: true,
    });
  }

  const debouncedHandlePropertyScroll = debounce(
    (propertyId: string) => !drawerExpanded && setSelectedPropertyId(propertyId),
    250,
  );

  const drawerIntersectionObs = new IntersectionObserver(
    (entries: IntersectionObserverEntry[], observer: IntersectionObserver) => {
      /**
       * This could work for vertical but atm it'd need more work to only pick up a true center card on larger screen
       * That'd involve updating root to be a scroll container ref and finding a sweet spot on margins
       * We'll also need to work with design on how to stick the view map button to the card after a snap center
       */
      if (entries.length === 1) {
        const { isIntersecting } = entries[0];
        if (isIntersecting) {
          // Note: this is supported more than useResizeObserver but just in case we'll chain it
          const propertyId = entries[0]?.target?.id.replace(PropertyCardContainerPrefix, "");
          propertyId && debouncedHandlePropertyScroll(propertyId);
        }
      }
    },
    {
      root: null,
      rootMargin: "0px",
      threshold: 1.0, // 100% of the target element must be visible
    },
  );

  useEffect(() => {
    return () => {
      drawerIntersectionObs.disconnect();
    };
  });

  // ----------------------------
  // initialize the selected market from the URL, if the url doesn't match a market maybe it is an old property url and redirect
  useEffect(() => {
    if (markets.length > 0) {
      const marketBPId = marketSlug!.split("-").pop();
      const initialMarket = markets.find((m) => m.blueprintMarketId === marketBPId);
      if (initialMarket) {
        setSelectedMarket(initialMarket);
      } else {
        window.location.href = `/inventory/m/${marketSlug}`;
      }
    }
  }, [marketSlug, markets]);

  return (
    <>
      <Helmet>
        <title>{`Browse Homes in ${selectedMarket?.name} | Homebound`}</title>
        <meta
          name="description"
          content={`Check out the homes we are building in ${selectedMarket?.name}. Choose your architecture style and customize your home. We make it simple.`}
        />
      </Helmet>
      <Nav />
      <main css={Css.h100.w100.bgWhite.overflow("clip").relative.z0.$}>
        {/* FILTER SUBHEADING */}
        <div
          css={
            Css.relative.z1.df.bt.bGray200
              .hPx(72)
              .w100.aic.px3.jcsb.boxShadow("0px 4px 8px rgba(53, 53, 53, 0.08), 0px 2px 16px rgba(53, 53, 53, 0.03)").$
          }
        >
          <div {...tid.title} css={Css.body18.add("letterSpacing", "0.02em").fw500.$}>
            {selectedMarket?.name && `${selectedMarket.name} homes`}
          </div>
          <div>
            <Button size="small" onClick={() => setShowFilters(true)} variant="shadowed">
              <>
                Filters <Icon name="filter" />
              </>
            </Button>
            {showFilters && (
              <BasicOverlay onClick={() => setShowFilters(false)} {...tid}>
                {/* We Can not use our standard modal pattern due to several issues, market routing, state management with modal persistence, more information SC-37214 */}
                <Modal isOpen={showFilters} title="Filter homes" close={() => setShowFilters(false)}>
                  <div css={Css.df.fdc.h100.overflowAuto.$}>
                    <div css={Css.df.fdc.overflowAuto.gap4.fg1.$}>
                      <div css={Css.w100.$}>
                        <SelectField
                          label="Location"
                          selectedOptions={selectedMarket ? [selectedMarket.name] : []}
                          onChange={handleLocationChange}
                          options={markets?.map((m) => m.name)}
                          allowDeselect={false}
                          {...tid.filterLocation}
                        />
                      </div>
                      <div css={Css.w100.$}>
                        <SelectField
                          label="Price"
                          placeholder="All"
                          selectedOptions={modalFilters.price ? [modalFilters.price] : []}
                          onChange={(price) => {
                            setModalFilters((prevForm) => ({ ...prevForm, price: price[0] }));
                          }}
                          options={Object.keys(priceOptions)}
                          {...tid.filterPrice}
                        />
                      </div>
                      <div css={Css.df.gap2.w100.$}>
                        <SelectField
                          label="Beds"
                          placeholder="All"
                          selectedOptions={modalFilters.minBeds ? [modalFilters.minBeds] : []}
                          onChange={(bedSelection) => {
                            setModalFilters((prevForm) => ({ ...prevForm, minBeds: bedSelection[0] }));
                          }}
                          options={Object.keys(minBedOptions)}
                          {...tid.filterBeds}
                        />
                        <SelectField
                          label="Baths"
                          placeholder="All"
                          selectedOptions={modalFilters.minBaths ? [modalFilters.minBaths] : []}
                          onChange={(bathSelection) => {
                            setModalFilters((prevForm) => ({ ...prevForm, minBaths: bathSelection[0] }));
                          }}
                          options={Object.keys(minBathOptions)}
                          {...tid.filterBaths}
                        />
                      </div>
                      <div css={Css.w100.$}>
                        <SelectField
                          label="Square Feet"
                          placeholder="All"
                          selectedOptions={modalFilters.squareFootage ? [modalFilters.squareFootage] : []}
                          onChange={(squareFootage) => {
                            setModalFilters((prevForm) => ({ ...prevForm, squareFootage: squareFootage[0] }));
                          }}
                          options={Object.keys(squareFootageOptions)}
                          {...tid.filterSqft}
                        />
                      </div>
                      <div css={Css.w100.$}>
                        <SelectField
                          label="Home Readiness"
                          placeholder="All"
                          selectedOptions={tagFilter}
                          onChange={(tags) => setTagFilter(tags)}
                          options={cutOffs.map((c) => c.marketingName)}
                          {...tid.filterTag}
                        />
                      </div>
                    </div>
                    <div css={Css.df.fdc.jcc.asStretch.pt2.hPx(72).$}>
                      <Button
                        onClick={() => {
                          setQueryFilters((prevForm) => ({
                            ...prevForm,
                            ...modalFilters,
                          }));
                          setShowFilters(false);
                        }}
                        variant="primary"
                      >
                        Apply Filters
                      </Button>
                    </div>
                  </div>
                </Modal>
              </BasicOverlay>
            )}
          </div>
        </div>
        {properties.length > 0 ? (
          <>
            <div css={Css.df.fg1.w100.h("80%").absolute.z0.$}>
              {/* MAP */}
              <div css={Css.h100.w100.$}>
                <Map
                  markers={properties.map((property) => {
                    const { id, latitude, longitude } = property;
                    // Markers are active if they are selected or hovered
                    const isActive = selectedPropertyId === id;

                    return {
                      id,
                      center: { lat: Number(latitude), lng: Number(longitude) },
                      isActive,
                      marker: (
                        <MapMarker
                          isActive={isActive}
                          onClick={() => {
                            setSelectedPropertyId(id);
                            document
                              .getElementById(`property-${id}`)
                              ?.scrollIntoView({ behavior: preferredScrollBehavior, block: "center" });
                          }}
                          data-testid={`mapMarker-${id}`}
                        />
                      ),
                    };
                  })}
                />
              </div>
            </div>
            {/* DRAWER */}
            {/* TODO: Animations, they're getting better but can still improve */}
            <motion.div
              layout
              css={{
                ...Css.bgWhite
                  .add("filter", "drop-shadow(0px -3px 16px rgba(213, 213, 213, 0.5))")
                  .borderRadius("32px 32px 0px 0px").aic.df.w100.fdc.z1.absolute.bottom0.$,
                ...(drawerExpanded ? Css.br0.h100.top0.$ : Css.hPx(185).$),
              }}
            >
              <LayoutGroup>
                {/* Closed - `Show More` Handle */}
                <motion.div
                  id="inventory-drawer-handle"
                  initial={{ y: -35, opacity: 0.5 }}
                  animate={{
                    y: [0, -25, 0, -15, 0],
                    opacity: 1,
                    transition: { bounce: 1, duration: 1.5, ease: [0, 0.71, 0.2, 1.01] },
                  }}
                  dragMomentum={false}
                  dragSnapToOrigin
                  dragConstraints={{ top: -25, bottom: 0 }}
                  dragElastic={{ top: 0, bottom: 0 }}
                  onDragEnd={(event, { offset }) => {
                    // swipe up only
                    if (offset.y < 0) {
                      setDrawerExpanded(true);
                    }
                  }}
                  drag="y"
                  css={Css.df.w75.jcsa.if(drawerExpanded).dn.$}
                >
                  <div css={Css.df.fdc.w50.aic.pt2.gapPx(11).bgWhite.borderRadius("48px 48px 0px 0px").$}>
                    <div css={Css.bgBlack.hPx(4).wPx(40).br4.$}></div>
                    <div css={Css.body14.fw500.$}>SHOW LIST</div>
                  </div>
                </motion.div>
                {/* Open - Absolute `Show Map` Btn  */}
                <motion.div
                  initial={false}
                  animate={drawerExpanded ? "vertical" : "horizontal"}
                  variants={{
                    horizontal: {
                      opacity: 0,
                      transition: {
                        duration: 0,
                        delay: 0,
                      },
                    },
                    vertical: {
                      opacity: 1,
                      transition: {
                        duration: 0.4,
                        delay: 0.5,
                        ease: [0, 0.71, 0.2, 1.01],
                      },
                    },
                  }}
                  css={Css.absolute.z2.w("fit-content").mxa.bottom("10%").$}
                >
                  <Button onClick={() => setDrawerExpanded(false)}>
                    <Icon name="map-pin-white" />
                    View Map
                  </Button>
                </motion.div>
                {/* Scroll List */}
                <motion.div
                  layout
                  variants={drawerScrollContainerVariants}
                  animate={drawerExpanded ? "vertical" : "horizontal"}
                  initial={false}
                  onAnimationComplete={(definition) => {
                    // Scroll selected property into view after PropertyCard.cardVariants transition ends (0.4s)
                    if (selectedPropertyId && typeof definition === "string") {
                      const element = document.getElementById(`property-${selectedPropertyId}`);
                      setTimeout(
                        () => element?.scrollIntoView({ behavior: preferredScrollBehavior, block: "center" }),
                        401,
                      );
                    }
                  }}
                  css={Css.df.w100.aic.overflowAuto.$}
                >
                  {properties.map((property) => (
                    <InventoryPropertyCard
                      key={property.id}
                      intersectionObserver={drawerIntersectionObs}
                      mobile={{ horizontal: !drawerExpanded }}
                      property={property}
                      url={createListingUrl(selectedMarket!, property)}
                      selectedPropertyState={{
                        selectedPropertyId,
                        setSelectedPropertyId: (id) => {
                          setSelectedPropertyId(id);
                          setDisplayContactFlyout(true);
                        },
                      }}
                    />
                  ))}
                </motion.div>
              </LayoutGroup>
            </motion.div>
          </>
        ) : (
          <div css={Css.df.w100.fg1.mt4.$}>
            {loadingMarkets || loadingInventory ? (
              <HBLoadingSpinner />
            ) : (
              <div css={Css.df.fdc.aic.jcc.gap2.$}>
                <div css={Css.wPx(291).hPx(145).$}>
                  <img src="/undraw_house.png" alt="No properties found"></img>
                </div>
                <div css={Css.fw500.fs("28px").tc.$}>No properties match that criteria</div>
                <div css={Css.fw300.fs("14px").$}>Try adjusting some of the selected filters</div>
                <Button
                  variant="secondary"
                  xss={Css.w("auto").mt1.$}
                  onClick={() => {
                    setTagFilter([]);
                    setModalFilters({});
                    setQueryFilters({});
                  }}
                  {...tid.clearFiltersButton}
                >
                  Clear Filters
                </Button>
              </div>
            )}
          </div>
        )}
      </main>
      {selectedPropertyId && displayContactFlyout && (
        <ContactUsFlyout
          property={properties.find((p) => p.id === selectedPropertyId)!}
          onClose={() => {
            setSelectedPropertyId(null);
            setDisplayContactFlyout(false);
          }}
          {...tid.contactUsFlyout}
        />
      )}
    </>
  );
}
