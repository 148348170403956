import { useCallback, useEffect, useMemo, useState } from "react";
import { ImageDisplay, StepWrapper, ToggleComponent } from "src/pages/configurator/components";
import { useConfiguratorContext } from "src/pages/configurator/Configurator.context";
import {
  getReservedOptionPrice,
  useMemoizedConflictsByConflictingOptionCode,
} from "src/pages/configurator/Configurator.utils";
import { AccordionOptionCard, ImagePlaceholder } from "~components";
import { Css } from "~generated/css";
import { ConfiguratorOptionFragment, OptionImageType } from "~generated/graphql";
import { defaultTestId, useTestIds } from "~utils";
import { OptionListContainer, OptionListItem } from "../components/options";

// Note: we will only show -
const LAYOUT_GROUP_CODES = {
  "customize-layout": [
    "DECKSTR002",
    "ELEVATOR01",
    "GARGENCL01",
    "STRG000001",
    "LFT2BED001",
    "DECKCVD006",
    "BED2DEN001",
    "DECKCVDSL2",
    "DECKCVD005",
    "WNDW2SL01",
    "LFT2BED002",
    "DOORSG12FT",
    "FLX2BED002",
    "BED2MGS001",
    "BED2PM2001",
    "DECKCVDSL1",
    "DECKSTR001",
    "BEDDECK001",
    "DECKSTR003",
    "DECKCVD002",
    "SUNROOM001",
    "SUNROOM003",
    "SUNROOM002",
    "EXTSTRAIL1",
    "EXTSTRAIL2",
    "EXTSTRAIL3",
    "EXTSTRAIL4",
    "EXTSTRAIL5",
    "EXTSTRAIL6",
    "METLROOF01",
    "METLROOF02",
    "METLROOF03",
    "METLROOF04",
    "METLROOF05",
    "GARGENCL03",
    "GRTKITEXT1",
    "BED2BUNK01",
    "GARGENCL04",
    "GARGENCL02",
    "GARGFLR002",
    "GARGFLR003",
    "DOORINT004",
  ],
  "add-plan-features": [
    "OUTSHW001",
    "OUTSHW002",
    "CABHALL001",
    "FRCDRLVR01",
    "CABBARWET1",
    "FIREPL0001",
    "CABFLX0001",
    "FRCDRFLX01",
    "ISLKITC001",
    "WNDKITC001",
    "CABUPHALL1",
  ],
};

export function FloorPlanOptionsStep() {
  const {
    loading,
    toggleOption,
    selectedOptions,
    availableOptionsByOptionType,
    optionConflictsByOptionCode,
    currentStep,
    plan,
    isReversed,
  } = useConfiguratorContext();
  const tid = useTestIds({}, "floor-plan-options-step");

  const [expandedCardTitle, setExpandedCardTitle] = useState<string>("Customize your layout");
  const selectedOptionIds = useMemo(() => selectedOptions.map((option) => option.option.id), [selectedOptions]);

  // used to determine whether image or floor plan is shown (toggle)
  const [viewFloorPlan, setViewFloorPlan] = useState(true);
  // keep track of the latest selected option
  const [lastOptionId, setLastOptionId] = useState<string>();
  /**
   *
   * Handle Interactive Floor Plans
   *
   * 1) Initial floor plan determined based on sort order of images when entering step regardless of what (if any) options are chosen
   *  - Note: we could instead show the floor plan that has a selected option (if at least one is chosen) if desired
   * 2) Upon an option selection, highlight the floor plan(s) that has the selected option
   *  - if the selected option isn't currently displayed on the floor plan, change the floor plan to show
   *    the next floor plan that has the selected option
   * 3) Upon de-selection of an option, remove the highlight the floor plan(s) that has the de-selected option
   *  - if the de-selected option isn't currently displayed on the floor plan, change the floor plan to show
   *    the next floor plan that has the de-selected option
   */

  // access the floor plan images
  const planFloorPlans = useMemo(() => plan?.floorPlans?.filter((fp) => !!fp) || [], [plan]);
  // used to determine which floor plan to show - initially  based on response (i.e. image sortOrder)
  const [visibleFloorIndex, setVisibleFloorIndex] = useState<number>(0);
  // passed into floor plan component to highlight selected options
  const selectedOptionCodes = useMemo(
    () => selectedOptions.map((option) => option.option.optionCode),
    [selectedOptions],
  );
  // stores a map of option codes by floor plan name
  const [svgOptionCodesByFloor, setSvgOptionCodesByFloor] = useState<Record<string, string[]>>({});
  // extract option codes for each floor plan and set in state
  useEffect(() => {
    void (async () => {
      const mappedObj = await getFloorToOptionCodes(planFloorPlans);
      setSvgOptionCodesByFloor(mappedObj);
    })();
  }, [planFloorPlans]);

  // determines whether current floor plan images has the "just selected / de-selected" option
  const maybeUpdateFloorPlanImage = useCallback(
    (optionCode: string) => {
      const floorPlanName = planFloorPlans[visibleFloorIndex]?.name;
      // attempt to switch out SVG floor plan if last selected option doesn't exist in currently displayed SVG
      if (optionCode && !svgOptionCodesByFloor[floorPlanName].includes(optionCode)) {
        const otherFloorPlanNames = planFloorPlans.map((pfi) => pfi.name);
        const floorsWithOption = otherFloorPlanNames.filter((otherFloorPlanName) => {
          return (
            svgOptionCodesByFloor[otherFloorPlanName] && svgOptionCodesByFloor[otherFloorPlanName].includes(optionCode)
          );
        });
        if (floorsWithOption.length > 0) {
          const floorIndex = planFloorPlans.findIndex((pfpi) => pfpi.name === floorsWithOption[0]);
          setVisibleFloorIndex(floorIndex);
        }
      }
    },
    [planFloorPlans, svgOptionCodesByFloor, visibleFloorIndex],
  );

  const [allOptions, customizeLayoutOptions, planFeatureOptions] = useMemo(() => {
    // first filter to the only option types that should render on this step
    const floorPlanOptions = availableOptionsByOptionType
      .filter((option) => currentStep.optionTypes.includes(option.name))
      .flatMap((optionType) => optionType.options);
    // then filter to options without a parent or whose parent is not on this page and selected
    const topFloorPlanOptions = floorPlanOptions.filter((fpo, i, allFloorPlanOptions) => {
      if (!fpo.parentOption) {
        return true;
      }
      return (
        !allFloorPlanOptions.map((o) => o.id).includes(fpo.parentOption.id) &&
        selectedOptionIds.includes(fpo.parentOption.id)
      );
    });
    // return grouped options
    return [
      floorPlanOptions,
      topFloorPlanOptions.filter((o) => LAYOUT_GROUP_CODES["customize-layout"].includes(o.optionCode)) || [],
      topFloorPlanOptions.filter((o) => LAYOUT_GROUP_CODES["add-plan-features"].includes(o.optionCode)) || [],
    ];
  }, [availableOptionsByOptionType, currentStep.optionTypes, selectedOptionIds]);

  // Get total number of options and selected for each grouping
  const [customizeLayoutCounter, planFeatureCounter] = useMemo(() => {
    const totalLayoutOptions = {
      current: customizeLayoutOptions.filter(({ id }) => selectedOptionIds.includes(id)).length,
      total: customizeLayoutOptions.length,
    };
    const totalPlanFeatureOptions = {
      current: planFeatureOptions.filter(({ id }) => selectedOptionIds.includes(id)).length,
      total: planFeatureOptions.length,
    };

    for (const layoutOption of customizeLayoutOptions) {
      const { options } = layoutOption;
      const filteredOptions = options.map((co) => allOptions.find((o) => o.id === co.id)!).filter((co) => !!co);
      totalLayoutOptions.current += filteredOptions.filter(({ id }) => selectedOptionIds.includes(id)).length;
      totalLayoutOptions.total += filteredOptions.length;
    }

    for (const planOption of planFeatureOptions) {
      const { options } = planOption;
      const filteredOptions = options.map((co) => allOptions.find((o) => o.id === co.id)!).filter((co) => !!co);
      totalPlanFeatureOptions.current += filteredOptions.filter(({ id }) => selectedOptionIds.includes(id)).length;
      totalPlanFeatureOptions.total += filteredOptions.length;
    }

    return [totalLayoutOptions, totalPlanFeatureOptions];
  }, [allOptions, customizeLayoutOptions, planFeatureOptions, selectedOptionIds]);

  const soConflictsByConflictingOptionCode = useMemoizedConflictsByConflictingOptionCode(
    selectedOptions,
    optionConflictsByOptionCode,
  );

  // track currently selected option so can use previously
  // selected option when an option is de-selected
  const selectedOption = useMemo(() => {
    const option = selectedOptions.find((option) => option.option.id === lastOptionId);
    if (option) {
      return option;
    }
    const previouslySelectedOption = selectedOptions[selectedOptions.length - 1];
    return previouslySelectedOption;
  }, [lastOptionId, selectedOptions]);

  const selectedOptionImages = useMemo(() => {
    if (selectedOption) {
      return selectedOption.option.images.filter((i) => i.type.code === OptionImageType.Display);
    }
    return [];
  }, [selectedOption]);

  function handleOptionClick(option: ConfiguratorOptionFragment) {
    toggleOption(option);
    maybeUpdateFloorPlanImage(option.optionCode);
    setLastOptionId(option.id);
    setViewFloorPlan(true);
  }

  return (
    <StepWrapper
      title="Floor Plan Options"
      loading={loading}
      description="Modify your home's layout and add built-in features to suit your lifestyle."
      left={
        <>
          {selectedOptionImages.length > 0 && (
            // toggle container
            <div css={Css.z1.absolute.topPx(24).left("50%").add("transform", "translate(-50%)").$}>
              <ToggleComponent
                offLabel="Option"
                onLabel="Floor Plans"
                value={viewFloorPlan}
                onClick={() => setViewFloorPlan(!viewFloorPlan)}
                {...tid.toggle}
              />
            </div>
          )}
          {viewFloorPlan && planFloorPlans.length > 0 ? (
            <ImageDisplay
              images={planFloorPlans}
              showDisclaimer={false}
              displayingDynamicSvgs
              selectedOptionCodes={selectedOptionCodes}
              imageIndex={visibleFloorIndex}
              onClick={setVisibleFloorIndex}
              isReversed={isReversed}
              {...tid.imageDisplay}
            />
          ) : !viewFloorPlan && selectedOptionImages.length > 0 ? (
            <ImageDisplay images={selectedOptionImages} {...tid.imageDisplay} />
          ) : (
            <ImagePlaceholder />
          )}
        </>
      }
      right={
        <>
          {/* GROUPING: Customize your layout */}
          <AccordionOptionCard
            title="Customize your layout"
            expanded={expandedCardTitle === "Customize your layout"}
            onExpanded={() => setExpandedCardTitle("Customize your layout")}
            counter={customizeLayoutCounter}
            {...tid.accordionOptionCard}
          >
            <OptionListContainer>
              {customizeLayoutOptions.map((option, idx) => {
                const { id, optionCode, name, description, priceInCents: planOptionPriceInCents, options } = option;
                const conflictData = soConflictsByConflictingOptionCode[optionCode];
                const filteredOptions = options
                  .map((co) => allOptions.find((o) => o.id === co.id)!)
                  .filter((co) => !!co);

                const selected = selectedOptionIds.includes(option.id);
                const displayPriceInCents = selected
                  ? getReservedOptionPrice(selectedOptions, option.id)
                  : option.priceInCents;

                return (
                  <OptionListContainer key={option.id}>
                    <OptionListItem
                      key={optionCode}
                      conflict={conflictData}
                      title={name}
                      description={description}
                      selected={selected}
                      onClick={() => handleOptionClick(option)}
                      priceInCents={displayPriceInCents}
                      optionId={id}
                      {...tid[`optionListItem_${defaultTestId(name)}_${id}`]}
                    />
                    {/* Child Option */}
                    {filteredOptions.map((co, i, allCos) => {
                      const fOptSelected = selectedOptionIds.includes(co.id);
                      const fOptDisplayPriceInCents = fOptSelected
                        ? getReservedOptionPrice(selectedOptions, co.id)
                        : co.priceInCents;

                      return (
                        <>
                          <OptionListItem
                            key={co.optionCode}
                            optionId={co.id}
                            title={co.name}
                            description={co.description}
                            selected={selectedOptionIds.includes(co.id)}
                            onClick={() => handleOptionClick(co)}
                            priceInCents={fOptDisplayPriceInCents}
                            child
                            {...tid[`optionListItem_${defaultTestId(co.name)}_${co.id}}`]}
                          />
                          {/* Grand Child Option */}
                          {co.options.map((_gco) => {
                            const gco = allOptions.find((o) => o.id === _gco.id)!;

                            const gcOptSelected = selectedOptionIds.includes(gco.id);
                            const gcOptDisplayPriceInCents = gcOptSelected
                              ? getReservedOptionPrice(selectedOptions, gco.id)
                              : gco.priceInCents;

                            return (
                              <div css={Css.pl2.$} key={gco.optionCode}>
                                <OptionListItem
                                  optionId={gco.id}
                                  title={gco.name}
                                  description={gco.description}
                                  selected={gcOptSelected}
                                  onClick={() => handleOptionClick(gco)}
                                  priceInCents={gcOptDisplayPriceInCents}
                                  child
                                  {...tid[`optionListItem_${defaultTestId(gco.name)}_${gco.id}`]}
                                />
                              </div>
                            );
                          })}
                        </>
                      );
                    })}
                  </OptionListContainer>
                );
              })}
            </OptionListContainer>
          </AccordionOptionCard>
          {/* GROUPING: Add plan features */}
          <AccordionOptionCard
            title="Add plan features"
            expanded={expandedCardTitle === "Add plan features"}
            onExpanded={() => setExpandedCardTitle("Add plan features")}
            counter={planFeatureCounter}
            {...tid.accordionOptionCard}
          >
            <OptionListContainer>
              {planFeatureOptions.map((option, idx) => {
                const { id, optionCode, name, description, priceInCents: planOptionPriceInCents, options } = option;
                const conflictData = soConflictsByConflictingOptionCode[optionCode];
                const filteredOptions = options
                  .map((co) => allOptions.find((o) => o.id === co.id)!)
                  .filter((co) => !!co);

                const selected = selectedOptionIds.includes(id);
                const displayPriceInCents = selected
                  ? getReservedOptionPrice(selectedOptions, id)
                  : planOptionPriceInCents;

                return (
                  <OptionListContainer key={option.id}>
                    <OptionListItem
                      key={optionCode}
                      conflict={conflictData}
                      title={name}
                      description={description}
                      selected={selected}
                      onClick={() => handleOptionClick(option)}
                      priceInCents={displayPriceInCents}
                      optionId={id}
                      {...tid[`optionListItem_${defaultTestId(name)}`]}
                    />
                    {filteredOptions.map((co, i, allCos) => {
                      const fOptSelected = selectedOptionIds.includes(co.id);
                      const fOptDisplayPriceInCents = fOptSelected
                        ? getReservedOptionPrice(selectedOptions, co.id)
                        : co.priceInCents;

                      return (
                        <OptionListItem
                          key={co.optionCode}
                          optionId={co.id}
                          title={co.name}
                          conflict={soConflictsByConflictingOptionCode[co.optionCode]}
                          description={co.description}
                          selected={fOptSelected}
                          onClick={() => handleOptionClick(co)}
                          priceInCents={fOptDisplayPriceInCents}
                          child
                          {...tid[`optionListItem_${defaultTestId(co.name)}`]}
                        />
                      );
                    })}
                  </OptionListContainer>
                );
              })}
            </OptionListContainer>
          </AccordionOptionCard>
        </>
      }
    />
  );
}

export async function getFloorToOptionCodes(
  planFloorPlans: {
    __typename?: "PlanFloorPlan" | undefined;
    id: string;
    name: string;
    imageUrl: string;
  }[],
) {
  const mappedResult = await Promise.all(
    planFloorPlans.map(async (pfi) => {
      const svgText = await (await fetch(pfi.imageUrl)).text();
      const optionGroupsText = svgText.match(/OPTION-CODE-(.*)/g);
      if (!optionGroupsText) return;
      // "OPTION-CODE-DECKSTR001_00000080197508046363056970000006024807355974841483_\" class=\"st12\">"
      const normalizedOptionCodes = optionGroupsText
        .map((ogt) => ogt.split("_")[0])
        // "OPTION-CODE-DECKSTR003\">"
        .map((oct) => oct.split("-").at(-1) ?? "")
        // "OUTSHW001\" class=\"st7\">"
        .map((oct) => oct.match(/[(a-z)(A-Z)\d]+/g))
        .flatMap((oct) => oct!);
      return [pfi.name, normalizedOptionCodes];
    }),
  );

  // filter out any undefined results, e.g. SVG didn't contain "OPTION-CODE-"
  const normalizedMappedResult = mappedResult.filter((mr) => !!mr);

  // map to object
  const mappedObj: Record<string, string[]> = {};
  Object.entries(normalizedMappedResult).forEach(([_, value]) => {
    // TODO: satisfy TS since value is an array of [string | string[]]
    if (Array.isArray(value) && typeof value[0] === "string") {
      // @ts-expect-error: Type 'string | string[]' is not assignable to type 'string[]'
      mappedObj[value[0]] = value[1];
    }
  });

  // example output:
  // {
  //  "Ground Level": ["DECKSTR001', 'UNDGRDGEN1'", ...] },
  //  "First Floor": ["DECKSTR001', 'SUNROOM001'", ...] },
  // }
  return mappedObj;
}
